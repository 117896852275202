import m0 from "views/chart";
import m1 from "views/config/index.js";
import m2 from "views/config/popup";
import m3 from "views/config/properties/chart";
import m4 from "views/config/properties/group";
import m5 from "views/config/properties/index.js";
import m6 from "views/config/properties/table";
import m7 from "views/config/properties/values";
import m8 from "views/filter";
import m9 from "views/main";
import m10 from "views/mode";
import m11 from "views/table";
import m12 from "views/toolbar";
import { JetView } from "webix-jet";

const views = { JetView };
views["chart"] = m0;
views["config"] = m1;
views["config/popup"] = m2;
views["config/properties/chart"] = m3;
views["config/properties/group"] = m4;
views["config/properties"] = m5;
views["config/properties/table"] = m6;
views["config/properties/values"] = m7;
views["filter"] = m8;
views["main"] = m9;
views["mode"] = m10;
views["table"] = m11;
views["toolbar"] = m12;

export default views;
