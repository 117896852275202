import { JetView } from "webix-jet";

export default class ModeView extends JetView {
	config() {
		const _ = this.app.getService("locale")._;
		this.Compact = this.getParam("compact");

		const askin = webix.skin.$active;
		const mini = webix.skin.$name == "mini" || webix.skin.$name == "compact";
		const config = {
			height: askin.toolbarHeight,
			cols: [
				{
					view: "segmented",
					localId: "modes",
					align: "middle",
					inputHeight: askin.inputHeight - askin.inputPadding * (mini ? 0 : 2),
					optionWidth: 80,
					width: 244,
					options: [
						{ id: "table", value: _("Table") },
						{ id: "tree", value: _("Tree") },
						{ id: "chart", value: _("Chart") },
					],
					on: {
						onChange: (v, o, c) => {
							if (c == "user") this.SetMode(v);
						},
					},
				},
				{ width: askin.dataPadding },
			],
		};

		if (this.Compact) {
			config.css = "webix_pivot_footer";
			config.cols[1].width = 0;
			config.cols.unshift({});
		}

		return config;
	}

	init() {
		this.State = this.getParam("state");

		this.on(this.State.$changes, "mode", mode => {
			this.$$("modes").setValue(mode);
		});
	}

	/**
	 * Sets Pivot mode
	 * @param {string} desired mode ("table", "tree", "data")
	 */
	SetMode(value) {
		this.State.mode = value;
	}
}
