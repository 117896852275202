function isSymbolAllowed(char) {
	const code = char.charCodeAt(0);
	return code <= 122 ? (code >= 48 && code <= 57) || code >= 65 : code > 191;
}

function getLastSymbolIndex(str, right) {
	if (right) {
		for (let i = 0; i < str.length; i++) if (!isSymbolAllowed(str[i])) return i;
		return str.length;
	} else
		for (let i = str.length - 1; i >= 0; i--)
			if (!isSymbolAllowed(str[i])) return i + 1;

	return 0;
}

function startsWith(text, start) {
	return text.toLowerCase().indexOf(start.toLowerCase()) === 0;
}

webix.protoUI(
	{
		name: "suggest-math",
		$enterKey() {
			const list = this.getList();
			if (this.isVisible() && !list.getSelectedId() && list.count()) {
				list.select(list.data.order[1]);
				this._addFirst = 1;
			}

			webix.ui.suggest.prototype.$enterKey.apply(this, arguments);

			webix.delay(() => {
				delete this._addFirst; //wait for input node change event
			});
		},
		defaults: {
			filter(item, value) {
				const editor = webix.$$(this.config.master);
				const cursor = editor.getInputNode().selectionStart;
				const str = value.substring(0, cursor);
				const search = str.substring(getLastSymbolIndex(str));
				const nextSymbol = value.charAt(cursor);

				if (search && (cursor === value.length || !isSymbolAllowed(nextSymbol)))
					value = search;

				//groups
				if (item.disabled) {
					const app = editor.$scope.app;

					if (item.id == "$fields") {
						const fields = app.getState().fields;
						return !!fields.find(obj => startsWith(obj.value, value));
					} else if (item.id == "$methods") {
						const _ = app.getService("locale")._;
						const operations = app.getService("local").operations;
						return !!operations.find(method => startsWith(_(method.id), value));
					}
				}

				return startsWith(item.value, value);
			},
		},
	},
	webix.ui.suggest
);

webix.protoUI(
	{
		name: "math-editor",
		$init() {
			this.$view.className += " webix_el_text";
		},
		$onBlur() {
			const suggest = webix.$$(this.config.suggest);
			const suggestClick = suggest.$view.contains(document.activeElement);

			webix.delay(() => {
				const focus = webix.UIManager.getFocus();
				if (focus && focus.config.pivotPropertyRemove) return;

				if (!suggestClick)
					this.callEvent("onChange", [this.getValue(), null, "user"]);
			});
		},
		setValue() {
			const suggest = webix.$$(this.config.suggest);

			//handle clicks on suggest items and add first suggest value via enter
			if (suggest.isVisible() || suggest._addFirst) return;

			webix.ui.text.prototype.setValue.apply(this, arguments);
		},
		$setValueHere(value) {
			this.setValueHere(value);
		},
		setValueHere(value) {
			const formula = this.getValue();
			const cursor = this.getInputNode().selectionStart;

			let str1 = formula.substring(0, cursor);
			let str2 = formula.substring(cursor);

			const lastSymbol = getLastSymbolIndex(str2, true);
			str1 += str2.substring(0, lastSymbol);
			str2 = str2.substring(lastSymbol);

			if (str1[str1.length - 1] == "(")
				str1 = str1.substring(0, str1.length - 1);

			str1 = str1.substring(0, getLastSymbolIndex(str1)) + value;

			const operations = this.$scope.app.getService("local").operations;
			const isMethod = operations.find(obj => obj.value == value);

			if (isMethod)
				//suggest called via up/down key
				str1 += str2[0] == "(" ? "" : "(";

			this.$setValue(str1 + str2);
			this.getInputNode().setSelectionRange(str1.length, str1.length);
		},
	},
	webix.ui.text
);
