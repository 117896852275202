import { JetView } from "webix-jet";

import "helpers/portlet";

export default class Property extends JetView {
	constructor(app, name, config) {
		super(app, name);

		if (!config) config = {};

		this.plusLabel = config.plusLabel;
		this.field = config.field;
	}
	config() {
		const askin = webix.skin.$active;
		return {
			borderless: true,
			type: "clean",
			paddingY: 8,
			rows: [
				{
					localId: "forms",
					type: "clean",
					rows: [],
				},
				{
					template: `<div class="webix_pivot_handle_add_value">
						<span class="webix_icon wxi-plus-circle"></span><span>${this.plusLabel}</span>
					</div>`,
					css: "webix_pivot_add_value",
					height: askin.inputHeight - 2 * askin.inputPadding,
					localId: "addValue",
					onClick: {
						webix_pivot_handle_add_value: () => {
							const forms = this.$$("forms").getChildViews();

							this.Add(null, forms.length);
							const input = forms[forms.length - 1].queryView({ name: "name" });

							if (input) {
								input.focus();
								webix.html.triggerEvent(
									input.getInputNode(),
									"MouseEvent",
									"click"
								);
							}

							if (
								this.field != "values" &&
								forms.length == this.app.getState().fields.length
							)
								this.$$("addValue").hide();
						},
					},
				},
			],
		};
	}
	init() {
		this.on(webix, "onAfterPortletMove", source => {
			if (source == this.$$("forms"))
				this.app.callEvent("property:change", [this.field, this.GetValue()]);
		});
		this.on(webix, "onPortletDrag", function(active, target) {
			if (active.$reorderOnly)
				return active.getParentView() === target.getParentView();
		});
	}
	ListTemplate(obj) {
		const input = this._activeInput;

		//wavg - complex operation, no need to correct inputs
		const wavg =
			this.field == "values" &&
			input &&
			!input.$destructed && // CorrectInputs calls SetValue, which recreates inputs
			input.getFormView().elements.operation.getValue() == "wavg";

		const corrections = this.getParentView().GetCorrections()[this.field];
		const value = obj.value;

		if (!wavg && corrections) {
			const includes = this.CheckCorrections(corrections, obj.id);

			let css = "webix_pivot_list_marker";
			if (includes) css += " webix_pivot_list_marker_fill";

			return `<div class="${css}"> ${value}</div>`;
		} else return value;
	}
	CheckCorrections(corrections, id) {
		if (this._activeInput && id == this._activeInput.getValue()) return true;

		const structure = this.app.getStructure();

		for (let i = 0; i < corrections.length; i++) {
			const name = corrections[i];
			const val = structure[name];

			if (val) {
				if (typeof val == "string") {
					if (val == id) return true;
				} else {
					for (let j = 0; j < val.length; j++) {
						let value = val[j];
						if (value.name) value = value.name;

						if (value == id) return true;
					}
				}
			}
		}
		return false;
	}
	GetValue() {
		const forms = this.$$("forms").getChildViews();
		let arr = [];

		forms.forEach(form => {
			const val = form.getChildViews()[0].getValues().name;

			if (val) arr.push(val);
		});

		return arr;
	}
	SetValue(val) {
		const layout = this.$$("forms");
		const forms = layout.getChildViews();

		for (let i = forms.length - 1; i >= 0; i--) layout.removeView(forms[i]);
		for (let i = 0; i < val.length; i++) {
			if (!val[i].external) this.Add(val[i], i);
		}
	}
	Add(val, i) {
		const extra = {};
		if (this.field == "values")
			extra.propertyId = val && val.id ? val.id : webix.uid();

		this.$$("forms").addView(
			webix.extend(
				{
					view: "pivot-portlet",
					mode: "replace",
					borderless: true,
					body: {
						view: "form",
						paddingY: 0,
						paddingX: 2,
						elements: [
							{
								margin: 2,
								cols: this.ItemConfig(val, i),
							},
						],
						on: {
							onChange: (val, old, config) => {
								if (config == "user")
									this.app.callEvent("property:change", [
										this.field,
										this.GetValue(),
									]);
							},
						},
					},
				},
				extra
			)
		);
	}
	ItemConfig(val) {
		return [
			{ width: webix.skin.$active.inputHeight }, //for portlet icon
			{
				view: "richselect",
				name: "name",
				value: this.PrepareValue(val),
				hidden: val && val.operation == "complex",
				options: {
					css: "webix_pivot_suggest",
					data: this.app.getState().fields,
					on: {
						onBeforeShow: function() {
							const input = webix.$$(this.config.master);
							const master = input.$scope;

							const data = master.GetValue();
							master._activeInput = input;

							this.getList().filter(val =>
								master.FilterSuggest(data, input.getValue(), val)
							);
						},
					},
					body: {
						template: obj => this.ListTemplate(obj),
					},
				},
			},
			{
				view: "icon",
				icon: "wxi-close",
				css: "webix_pivot_close_icon",
				pivotPropertyRemove: true,
				click: function() {
					const master = this.$scope;
					master.$$("addValue").show();
					master
						.$$("forms")
						.removeView(this.queryView("pivot-portlet", "parent"));
					master.app.callEvent("property:change", [
						master.field,
						master.GetValue(),
					]);
				},
			},
		];
	}
	PrepareValue(val) {
		if (val) {
			if (typeof val == "object") val = val.name;
			if (webix.isArray(val)) val = val[0];
		} else val = "";

		return val;
	}
	FilterSuggest(data, activeVal, val) {
		val = val.id;

		if (val == activeVal) return true;

		return !data.find(item => {
			if (item.name) item = item.name;

			return val == item;
		});
	}
}
