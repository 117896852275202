export default class Backend {
	/**
	 * @constructor
	 * @param {App} app - Jet App instance
	 * @param {string} url - the URL to load/save data
	 */
	constructor(app, url) {
		this.app = app;
		this._url = url;
	}
	/**
	 * Prepares url
	 * @param {string} path - end of url
	 * @returns {string} ready url
	 */
	url(path) {
		return this._url + (path || "");
	}
	/**
	 * Gets data from backend
	 * @returns {Promise} response from server
	 */
	data() {
		let config;
		let url = this.url();

		if (this.app.config.externalProcessing) {
			url += "/data";
			const state = this.app.getState();
			const mode = state.mode;

			config = {
				structure: state.structure,
				mode,
			};

			if (mode != "chart") config.table = state.datatable;
		}

		return webix.ajax(url, config).then(res => res.json());
	}
	/**
	 * Gets field values from backend
	 * @returns {Promise} response from server
	 */
	collectFieldValues(field) {
		return webix.ajax(this.url("/fields/" + field)).then(res => res.json());
	}
}
