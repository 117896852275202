import { JetView } from "webix-jet";
import Property from "views/config/properties";
import ValuesProperty from "views/config/properties/values";
import GroupProperty from "views/config/properties/group";
import ChartSettings from "views/config/properties/chart";
import TableSettings from "views/config/properties/table";

export default class ConfigView extends JetView {
	config() {
		const _ = this.app.getService("locale")._;

		this.State = this.app.getState();
		this.Compact = this.getParam("compact", true);

		const toolbar = {
			type: "form",
			borderless: true,
			padding: {
				left: 16,
				right: 14, //button
				top: 8,
				bottom: 4,
			},
			cols: [
				{},
				{
					view: "button",
					label: _("Done"),
					hotkey: "esc",
					autowidth: true,
					css: "webix_primary",
					click: () => this.ToggleForm(),
				},
			],
		};

		const structure = {
			borderless: true,
			view: "scrollview",
			scroll: "y",
			body: {
				view: "accordion",
				css: "webix_pivot_configuration",
				localId: "settings",
				multi: true,
				type: "space",
				padding: {
					left: 16,
					right: 16,
					top: 4,
					bottom: 16,
				},
				margin: 20,
				rows: [
					this.GroupConfig(
						_("Rows"),
						"pt-rows",
						{
							name: "rows",
							$subview: new Property(this.app, "", {
								field: "rows",
								plusLabel: _("Add row"),
							}),
						},
						"table"
					),
					this.GroupConfig(
						_("Columns"),
						"pt-columns",
						{
							name: "columns",
							$subview: new Property(this.app, "", {
								field: "columns",
								plusLabel: _("Add column"),
							}),
						},
						"table"
					),
					this.GroupConfig(_("Values"), "pt-values", {
						name: "values",
						$subview: ValuesProperty,
					}),
					this.GroupConfig(
						_("Group By"),
						"pt-group",
						{
							name: "groupBy",
							$subview: GroupProperty,
						},
						"chart"
					),
					this.GroupConfig(_("Filters"), "pt-filter", {
						name: "filters",
						$subview: new Property(this.app, "", {
							field: "filters",
							plusLabel: _("Add filter"),
						}),
					}),
					this.GroupConfig(
						_("Chart"),
						"pt-chart",
						{
							$subview: ChartSettings,
						},
						"chart"
					),
					this.GroupConfig(
						_("Table"),
						"wxi-columns",
						{
							$subview: TableSettings,
						},
						"table"
					),
					{},
				],
			},
		};

		return {
			margin: 0,
			rows: [toolbar, structure],
		};
	}

	init() {
		this.on(this.State.$changes, "readonly", (_v, o) => {
			if (!webix.isUndefined(o)) this.ToggleForm();
		});
	}

	ready() {
		this.on(this.app, "property:change", (field, value) => {
			this.HandleFieldChange(field, value);
		});

		this.on(this.State.$changes, "structure", () => {
			if (!this.innerChange) this.SetValues();
		});

		this.on(this.State.$changes, "mode", (mode, oldMode) => {
			const isChart = mode == "chart";

			this.$$("settings").showBatch(isChart ? "chart" : "table");

			//columns can be updated in structure via groupBy
			if (oldMode && (isChart || oldMode == "chart")) this.SetValues();
		});
	}
	/** Toggles configuration form */
	ToggleForm() {
		this.State.config = !this.State.config;
	}
	/** Sets values into a  configuration form */
	SetValues() {
		const structure = this.State.structure;
		const inputs = ["rows", "columns", "values", "filters", "groupBy"];

		inputs.forEach(input => {
			const value = structure[input] || this.State[input];
			const view = this.getSubView(input);
			view.SetValue(value);
		});
	}
	/**
	 * Applies changes in form fields to data structure
	 * @param {string} field - field name
	 * @param {string|Array} value - field value
	 */
	HandleFieldChange(field, value) {
		const structure = webix.copy(this.State.structure);

		if (field == "filters") value = this.CorrectFilters(structure, value);
		else this.CorrectInputs(structure, field, value);

		structure[field] = value;
		this.innerChange = true;
		this.app.setStructure(structure);
		delete this.innerChange;
	}
	/**
	 * Prepares configuration for an accordion item with form fields
	 * @param {string} name - accordion item header name
	 * @param {string} icon - accordion item icon
	 * @param {Object} config - object with form fields for this accordion item
	 * @param {string} batch - batch name, used to control visibility
	 * @returns {Object} configuration of the accordion item body
	 */
	GroupConfig(name, icon, config, batch) {
		return {
			batch,
			header: `
				<span class="webix_icon webix_pivot_config_icon ${icon}"></span>
				<span class="webix_pivot_config_label">${name}</span>
			`,
			body: config,
			borderless: true,
		};
	}
	/** Returns an object with fields that need to be corrected when some field changes */
	GetCorrections() {
		return {
			rows: ["columns", "values", "groupBy"],
			columns: ["rows", "values"],
			values: ["rows", "columns", "groupBy"],
			groupBy: ["rows", "values"],
		};
	}
	/**
	 * Prepares filters before applying them to data structure
	 * @param {Object} structure - a copy of the current data structure
	 * @param {Array} value - "filters" field value
	 */
	CorrectFilters(structure, value) {
		for (let i = 0; i < value.length; i++) {
			const active = structure.filters.find(filter => {
				if (filter.name == value[i]) return true;
			});
			value[i] = { name: value[i] };
			if (active && !active.external) value[i].value = active.value;
		}

		const external = structure.filters.filter(filter => filter.external);
		value = value.concat(external);

		return value;
	}
	/**
	 * Prepares form fields before applying them to data structure
	 * @param {Object} structure - a copy of the current data structure
	 * @param {string} field -  field name
	 * @param {string|Array} value -  field value
	 */
	CorrectInputs(structure, field, value) {
		const inputs = this.GetCorrections()[field];

		if (inputs) {
			if (typeof value == "string") value = [value];

			value = value.map(v => (v.name ? v.name : v));

			inputs.forEach(input => {
				const view = this.getSubView(input);
				let values = view.GetValue();

				if (typeof values == "string") {
					if (value.find(v => v == values)) values = "";
				} else
					values = values.filter(v => {
						if (v.name) v = v.name;

						return value.indexOf(v) == -1;
					});

				structure[input] = values;
				view.SetValue(values);
			});
		}
	}
}
