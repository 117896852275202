webix.protoUI(
	{
		name: "pivot-portlet",
		$reorderOnly: true,
		$drag: function(object) {
			webix.html.addCss(this.$view, "portlet_in_drag");
			const ctx = webix.DragControl.getContext();
			ctx.source = ctx.from = object;

			// hide suggests
			webix.callEvent("onClick", []);

			const local = this.$scope.app.getService("local");
			const values = this.getChildViews()[0].getValues();

			let text = "";

			if (values.operation == "complex") text = local.fixMath(values.math);
			else {
				text = values.name ? local.getField(values.name).value : "";

				if (values.name2)
					text += (text ? ", " : "") + local.getField(values.name2).value;
				else if (!text) {
					const _ = this.$scope.app.getService("locale")._;
					text = _("Field not defined");
				} else if (values.operation) text += ` (${values.operation})`;
			}

			const askin = webix.skin.$active;
			const style = `width:${this.$width - askin.inputHeight}px;height:${
				this.$height
			}px;`;
			return `<div class="webix_pivot_portlet_drag" style="${style}">
				<span class="webix_icon ${this.config.icon}"></span>${text}
			</div>`;
		},
	},
	webix.ui.portlet
);
