import Property from "views/config/properties";

export default class GroupProperty extends Property {
	constructor(app, name) {
		super(app, name);

		this.field = "groupBy";
	}
	config() {
		return {
			padding: 10,
			rows: [
				{
					view: "richselect",
					localId: "group",
					options: {
						css: "webix_pivot_suggest",
						data: this.app.getState().fields,
						body: {
							template: obj => this.ListTemplate(obj),
						},
						on: {
							onBeforeShow: () => {
								this._activeInput = this.$$("group");
							},
						},
					},
					on: {
						onChange: (value, oldValue, source) => {
							if (source == "user")
								this.app.callEvent("property:change", [this.field, value]);
						},
					},
				},
			],
		};
	}
	init() {
		this.State = this.getParam("state", true);
	}
	GetValue() {
		return this.$$("group").getValue();
	}
	SetValue(val) {
		this.$$("group").setValue(val);
	}
}
