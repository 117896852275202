import { JetView } from "webix-jet";
import ModeView from "views/mode";
import ToolbarView from "views/toolbar";

import { initRLayout } from "@xbs/jet-helpers";

export default class MainView extends JetView {
	config() {
		initRLayout();

		const fCompact = this.getParam("forceCompact");
		if (!webix.isUndefined(fCompact)) this.setParam("compact", fCompact);
		this.Compact = this.getParam("compact");

		const rows = [
			ToolbarView,
			{
				view: "r-layout",
				localId: "main",
				cols: [{ $subview: true }],
			},
		];

		if (this.Compact) {
			rows.push(ModeView, {
				$subview: true,
				name: "edit",
				popup: true,
			});
		} else {
			rows[1].cols.push({
				view: "proxy",
				maxWidth: 450,
				localId: "edit",
				css: "webix_pivot_config_container webix_shadow_medium",
				borderless: true,
				hidden: true,
				body: { $subview: true, name: "edit" },
			});
		}

		return {
			margin: 0,
			rows,
			view: webix.isUndefined(fCompact) ? "r-layout" : "layout",
		};
	}

	init() {
		const state = this.getParam("state");

		this.$$("main").sizeTrigger(
			this.app,
			mode => this.SetCompactMode(mode),
			!!this.Compact
		);

		this.on(state.$changes, "mode", mode => {
			this.show("./" + (mode == "chart" ? "chart" : "table"));
		});

		this.on(state.$changes, "config", val => {
			if (val) this.ShowConfig();
			else this.HideConfig();
		});
	}

	/** Shows configuration window*/
	ShowConfig() {
		if (this.Compact) {
			this.show("config.popup/config", {
				target: "edit",
			});
		} else {
			this.$$("edit").show();
			this.show("config", {
				target: "edit",
			});
		}
	}

	/** Hides configuration window*/
	HideConfig() {
		// we need to destroy the view inside first
		this.show("_blank", { target: "edit" });

		if (!this.Compact) this.$$("edit").hide();
	}

	SetCompactMode(mode) {
		//let it render, then recreate
		webix.delay(() => {
			this.setParam("compact", mode);
			if (!mode) webix.fullscreen.exit();
			this.refresh();
		});
	}
}
