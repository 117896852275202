import { JetView } from "webix-jet";

export default class FilterView extends JetView {
	config() {
		this.Local = this.app.getService("local");

		return {
			view: "popup",
			css: "webix_pivot_filter_popup",
			body: {
				view: "filter",
				template: item => item.label,
				field: "value",
				on: {
					onChange: config => {
						if (config == "user") {
							const state = this.app.getState();
							const structure = webix.copy(state.structure);

							let value;
							structure.filters = structure.filters.filter(filter => {
								const exists = filter.name == this.field;
								const inner = exists && !filter.external;

								if (inner) value = filter.value = this.filter.getValue();

								return !exists || inner;
							});

							state.structure = structure;

							this.app.callEvent("filter:change", [this.field, value]);
						}
					},
				},
			},
		};
	}
	Show(pos, filterObj) {
		const popup = this.getRoot();
		const filter = (this.filter = popup.getBody());
		this.field = filterObj.name;
		const list = filter.getChildViews()[2];

		const field = this.Local.getField(this.field);

		this.Local.collectFieldValues(this.field).then(values => {
			list.clearAll();
			list.parse(webix.copy(values));

			filter.define({ mode: field.type });
			filter.config.value = "";
			filter.setValue(webix.copy(filterObj.value || {}));

			popup.show(pos);
		});
	}
}
