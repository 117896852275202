import * as rengine from "@xbs/rengine";

export default class LocalData {
	/**
	 * @constructor
	 * @param {App} app - Jet App instance
	 */
	constructor(app) {
		this._app = app;
		this._store = {}; //aggregated data

		this._filtersHash = {};
		this._state = app.getState();

		if (app.config.externalProcessing) this._setOperations();
		else {
			this._data = [];
			this._initRengine();
		}
	}
	/** Set list of avaible operations; adds custom operations from config */
	_setOperations() {
		this.operations = [
			{ id: "sum", fields: 1, branchMode: "result" },
			{ id: "min", fields: 1, branchMode: "result" },
			{ id: "max", fields: 1, branchMode: "result" },
			{ id: "avg", fields: 1, branchMode: "raw" },
			{ id: "wavg", fields: 2, branchMode: "raw" },
			{ id: "count", fields: 1, branchMode: "raw" },
			{ id: "any", fields: 1, branchMode: "result" },
			{ id: "complex" },
		];

		const config = this._app.config;
		const extraOperations = this._app.config.operations;
		if (extraOperations) {
			if (config.externalProcessing)
				this.operations = this.operations.concat(extraOperations);
			else
				for (let name in extraOperations) {
					let operation = extraOperations[name];
					let fields, branchMode, hidden;

					if (typeof operation == "object") {
						fields = operation.fields || operation.handler.length;
						branchMode = operation.branchMode;
						hidden = operation.hidden;
						operation = operation.handler;
					} else fields = operation.length;

					this._reng.addMath(name, operation);

					if (!hidden)
						this.operations.push({
							id: name,
							fields,
							branchMode,
						});
				}
		}
	}
	/** Set list of avaible total operations */
	_setTotalOperations() {
		this.totalOperations = {};
		const totalOps = this._app.config.totalOperations;

		if (totalOps)
			for (let name in totalOps) {
				const op = totalOps[name];
				const all = typeof totalOps[name] == "string";
				["footer", "group", "column"].forEach(type => {
					if (all || op[type]) {
						if (!this.totalOperations[type]) this.totalOperations[type] = {};
						this.totalOperations[type][name] = all ? op : op[type];
					}
				});
			}
	}
	/** Sets default filter rules */
	_setFilters() {
		for (let type in webix.filters) {
			this._reng.addComparator(type, v => test => {
				if (type == "date") test = test.valueOf();

				if (!v) return true;
				else if (v.includes) return v.includes.indexOf(test) != -1;
				else if (!v.condition.filter) return true;
				else
					return webix.filters[type][v.condition.type](
						test,
						v.condition.filter
					);
			});
		}
	}
	/** Adds predicates from config */
	_setPredicates() {
		const predicates = this._app.config.predicates;
		if (predicates)
			for (let name in predicates)
				this._reng.addPredicate(name, predicates[name]);
	}
	/**
	 * Returns fields that contain data
	 * @param {object} firstRow - first row in data
	 * @returns {array} data fields
	 */
	getFields(firstRow) {
		let fields = this._app.config.fields;

		if (!fields) {
			fields = [];

			for (let i in firstRow) {
				let type;

				const dataType = typeof firstRow[i];
				switch (dataType) {
					case "string":
						type = "text";
						break;
					case "number":
						type = dataType;
						break;
					default:
						type = "date";
				}

				fields.push({ id: i, value: i, type });
			}
		}

		return fields;
	}
	/**
	 * Loads data and populates store
	 * @param {boolean} force - if true, data will be reloaded
	 * @returns {Promise} promise that resolves with store array
	 */
	getData(force) {
		if (!force && this.useOldData())
			return this._dataLoad || webix.promise.resolve(this._store);

		const externalProcessing = this._app.config.externalProcessing;
		if (externalProcessing || !Object.keys(this._store).length || force) {
			return (this._dataLoad = this._app
				.getService("backend")
				.data()
				.then(data => {
					if (externalProcessing)
						this._store = this._rengineToWebix(data.result, data.header);
					else {
						this._table = this.getTable(data);
						this._reng.addTable(this._table);
						this._store = this.getPivotData();
					}
					this._filtersHash = {};
					return this._store;
				})
				.catch(error => this.loadError(error))
				.finally(() => delete this._dataLoad));
		} else {
			this._store = this.getPivotData();
			return webix.promise.resolve(this._store);
		}
	}
	/** Checks if pivot should use last aggregated data **/
	useOldData() {
		const state = this._state;
		let newConfig = `${state.mode}|${JSON.stringify(state.structure)}`;
		if (state.mode == "chart") {
			const type = state.chart.type;
			newConfig += `|${type == "pie" || type == "donut"}`;
		} else newConfig += `|${JSON.stringify(state.datatable)}`;

		const refresh = this._old == newConfig;
		this._old = newConfig;
		return refresh;
	}
	/** Inits math module */
	_initRengine() {
		this._reng = new rengine.Analytic();

		this._setFilters();
		this._setOperations();
		this._setTotalOperations();
		this._setPredicates();
	}
	/**
	 * Сonverts data to pivot readable form
	 * @returns {Object} object with aggregated data and its parameters
	 */
	getPivotData() {
		if (!this._table)
			return {
				data: [],
				header: [],
				total: [],
				marks: [],
			};

		const structure = this._state.structure;
		const mode = this._state.mode;
		const table = this._state.datatable;

		this.setDimensions();

		const filters = {};
		for (let i = 0; i < structure.filters.length; i++) {
			const filter = structure.filters[i];

			const fields = this._state.fields;
			const field = fields.find(field => field.id == filter.name);

			filters[filter.name] = { [field.type]: filter.value };
		}

		const isTable = mode != "chart";

		//groups data
		const res = this._reng.compact(this._table.id, {
			transpose: isTable && table.transpose,
			rows: this.getRows(),
			cols: this.getColumns(),
			limit: this.getLimits(),
		});

		const ops = this.getOps();

		try {
			let result;

			//plain data
			if (mode == "table")
				result = res.toArray({
					filters,
					ops,
					//remove repetitions in 'parent' col in 'table' mode
					cleanRows: table.cleanRows,
				});
			//groupped data
			else
				result = res.toNested({
					filters,
					ops,
				});

			let headerConfig;
			if (isTable)
				headerConfig = {
					meta: !(table.transpose && mode != "chart"),
					nonEmpty: true,
				};

			return this._rengineToWebix(result, res.toXHeader(result, headerConfig));
		} catch (e) {
			return this.loadError();
		}
	}
	/**
	 * Updates math with custom total methods
	 * @param {string} type - total operation type (group/footer/total)
	 * @param {string} math - operation math
	 * @param {Array} methodsMatch - math methods pos
	 * @returns {string} updated math
	 */
	_updateTotalMath(type, math, methodsMatch) {
		for (let i = methodsMatch.length - 1; i >= 0; i--) {
			const match = methodsMatch[i];
			math =
				math.substring(0, match.index + match[1].length) +
				(this.totalOperations[type][match[2]] || match[2]) +
				math.substring(match.index + match[0].length - 1, math.length);
		}
		return math;
	}
	/**
	 * Applies math operations to data and groups
	 * @returns {Object} ops
	 */
	getOps() {
		const commonFormat = this._app.config.format;
		const state = this._state;
		const table = state.datatable;
		const mode = state.mode;
		const vals = state.structure.values;
		const ops = [];

		for (let i = 0; i < vals.length; i++) {
			const valueFormat = vals[i].format;
			let format;

			if (valueFormat || commonFormat)
				format = value => (valueFormat || commonFormat)(value, vals[i]);

			const operation = vals[i].operation;
			const color = vals[i].color;

			const isComplex = operation == "complex";
			let name = isComplex ? vals[i].math : vals[i].name;
			name = webix.isArray(name) ? name.join(",") : name;

			const math = isComplex ? name : `${operation}(${name})`;

			let branchMode;
			if (!isComplex) branchMode = this.getOperation(operation).branchMode;

			const op = {
				math,
				branchMode: branchMode || "raw",
				label: name,
				meta: { operation, format, color },
				column: [],
				row: [],
				marks: [],
			};

			const total = this.totalOperations;
			let methodsMatch;
			if (Object.keys(total).length) {
				const methodsRegex = new RegExp(
					"(\\(|,|\\+|-|\\/|\\*|\\s|^)(" +
						this.operations.map(method => method.id).join("|") +
						")\\(",
					"g"
				);
				methodsMatch = Array.from(math.matchAll(methodsRegex));
			}

			if (total.group)
				op.branchMath = this._updateTotalMath("group", math, methodsMatch);

			if (mode != "chart") {
				if (
					table.footer &&
					((table.footer == "sumOnly" && operation == "sum") ||
						table.footer != "sumOnly")
				)
					op.column.push({
						math: total.footer
							? this._updateTotalMath("footer", math, methodsMatch)
							: math,
						as: "" + i,
					});

				if (
					table.totalColumn &&
					((table.totalColumn == "sumOnly" && operation == "sum") ||
						table.totalColumn != "sumOnly")
				)
					op.row.push({
						math: total.column
							? this._updateTotalMath("column", math, methodsMatch)
							: math,
						as: "" + i,
					});

				if (table.minY) {
					op.column.push({
						as: "minY",
						math: "min(group)",
						source: "result",
					});
					op.marks.push({
						name: "webix_min_y",
						check: (v, column) => v == column.minY,
					});
				}
				if (table.maxY) {
					op.column.push({
						as: "maxY",
						math: "max(group)",
						source: "result",
					});
					op.marks.push({
						name: "webix_max_y",
						check: (v, column) => v == column.maxY,
					});
				}
				if (table.minX) {
					op.row.push({
						as: "minX" + math,
						math: "min(group)",
						source: "result",
					});
					op.marks.push({
						name: "webix_min_x",
						check: (v, column, row) => v == row["minX" + math],
					});
				}
				if (table.maxX) {
					op.row.push({
						as: "maxX" + math,
						math: "max(group)",
						source: "result",
					});
					op.marks.push({
						name: "webix_max_x",
						check: (v, column, row) => v == row["maxX" + math],
					});
				}
			}
			ops.push(op);
		}
		return ops;
	}
	/** Convert rengine output data to pivot readable form **/
	_rengineToWebix(result, header) {
		const state = this._state;
		const transpose = state.datatable.transpose;

		if (state.mode == "chart") return this.getChartData(result, header);

		const footer = this.getFooter(header, result, transpose);
		header = this.getHeader(header);

		let column;
		const total = state.datatable.totalColumn;
		if (total) {
			const vals = state.structure.values;
			column = result.rowData.map((obj, i) => {
				const res = [];
				if (transpose) {
					const index = i % vals.length;
					res.push(obj[index]);
				} else
					for (let key in obj) {
						if (key.indexOf("minX") == -1 && key.indexOf("maxX") == -1)
							res[key] = obj[key];
					}
				return res.filter(v => v || v === 0);
			});
		}

		return {
			data: result.tree || result.data,
			header,
			marks: result.marks,
			footer,
			totalColumn: column,
		};
	}
	/**
	 * Handle load error
	 * @param {XMLHttpRequest} server error (customizations)
	 * @returns {Object} empty data
	 */
	loadError() {
		webix.message({
			text: this._app.getService("locale")._("Incorrect formula in values"),
			type: "error",
		});

		return {
			data: [],
			values: [],
			header: [],
			marks: [],
			footer: [],
			totalColumn: [],
		};
	}
	/**
	 * Prepares data for tree nodes
	 * @param {Object} obj - item data as returted by Pivot engine
	 * @returns {Object} item data ready for parsing in Webix Tree
	 */
	_toTree(obj) {
		let item = obj.values;

		// server returns object
		if (!webix.isArray(item)) {
			let arr = [];
			for (let name in item) arr[name] = item[name];
			item = arr;
		}

		item.unshift(""); //header ids start from 1
		if (obj.data) {
			item.open = true;
			item.data = obj.data.map(r => {
				return this._toTree(r);
			});
		} else item.id = obj.id; //row ids
		return item;
	}
	/**
	 * Prepares a storage for raw data before aggregation
	 * @param {Array} data - initial data set
	 * @returns {Object} object with storage data and parameters
	 */
	getTable(data) {
		const fields = this.getFields(data[0]);
		this._state.fields = fields;
		this._data = data = this.prepareData(data, fields);

		return {
			id: "webixpivot",
			prepare: true,
			driver: "raw", //or "columns"
			fields: webix.copy(fields),
			data,
		};
	}
	/**
	 * Prepares data: parse dates, values template
	 * @param {Array} data - initial data set
	 * @returns {Array} data ready to parse
	 */
	prepareData(data, fields) {
		fields = fields.filter(field => field.prepare || field.type == "date");

		if (fields.length) {
			data = data.map(item => {
				fields.forEach(field => {
					item[field.id] = field.prepare
						? field.prepare(item[field.id])
						: new Date(item[field.id]);
				});
				return item;
			});
		}

		return data;
	}
	/**
	 * Gets field values
	 * @param {string} field name
	 * @returns {Promise} promise that resolves with field values
	 */
	collectFieldValues(field) {
		if (this._filtersHash[field])
			return webix.promise.resolve(this._filtersHash[field]);

		const app = this._app;
		if (app.config.externalProcessing)
			return (this._filtersHash[field] = app
				.getService("backend")
				.collectFieldValues(field));

		const fieldObj = this.getField(field);

		const hash = {};
		const values = [];
		for (let i = 0; i < this._data.length; i++) {
			let value = this._data[i][field];
			if (value || value === 0) {
				let label = value;

				if (fieldObj.type == "date") value = value.valueOf();

				if (!hash[value]) {
					hash[value] = true;

					if (fieldObj.predicate)
						label = app.config.predicates[fieldObj.predicate](label);

					values.push({ value, id: value, label });
				}
			}
		}

		this._filtersHash[field] = values;
		return webix.promise.resolve(values);
	}
	/**
	 * Apply custom fields names and methods locale to complex math
	 * @param {String} complex math
	 * @returns {String} complex math with correct fields and math methods names
	 */
	fixMath(math) {
		const _ = this._app.getService("locale")._;

		const fields = this._state.fields;
		const fieldsRegex = new RegExp(
			fields.map(field => "\\b" + field.id + "\\b(?!\\()").join("|"),
			"g"
		);

		const methods = this.operations;
		const methodsRegex = new RegExp(
			methods.map(method => "\\b" + method.id + "\\b\\(").join("|"),
			"g"
		);

		return math
			.replaceAll(fieldsRegex, id => fields.find(obj => obj.id == id).value)
			.replaceAll(
				methodsRegex,
				method => _(method.substring(0, method.length - 1)) + "("
			);
	}
	/**
	 * Finds field
	 * @param {String} field id
	 * @returns {Object} field
	 */
	getField(id) {
		return this._state.fields.find(obj => obj.id == id);
	}
	/**
	 * Finds operation
	 * @param {String} operation id
	 * @returns {Object} operation
	 */
	getOperation(id) {
		return this.operations.find(obj => obj.id == id);
	}
	/**
	 * Returns columns for aggregation
	 * @returns {Array} array of field names for columns
	 */
	getColumns() {
		const struct = this._state.structure;
		if (this._state.mode == "chart" && struct.groupBy) return [struct.groupBy];
		else return struct.columns;
	}
	/**
	 * Returns for aggregation
	 * @returns {Array} array of field names for rows
	 */
	getRows() {
		return this._state.mode == "chart" ? [] : this._state.structure.rows;
	}
	/** Sets limits for raw and output data
	 * @returns {Object} object with limits. By default math engine uses
	 * { rows: 10000, columns: 5000, raws: Infinity }
	 */
	getLimits() {
		return {};
	}
	/**
	 * Prepares table footer
	 * @param {Object} hdata - object with header config
	 * @param {Object} result - aggregated data
	 * @param {boolean} transpose - values on the row axis
	 * @returns {Array} array with footer lines
	 */
	getFooter(hdata, result, transpose) {
		const footer = [];
		hdata.nonEmpty.forEach(i => {
			let arr = [];
			const item = result.columnData[i] || {};

			for (let key in item)
				if (key.indexOf("minY") == -1 && key.indexOf("maxY") == -1) {
					if (transpose) arr[key] = item[key];
					else arr.push(item[key]);
				}

			if (transpose) arr = arr.filter(v => v || v === 0);

			if (arr.length) footer[i] = arr;
		});
		return footer;
	}
	/**
	 * Prepares table header
	 * @param {Object} hdata - object with header config
	 * @returns {Array} array with header lines
	 */
	getHeader(hdata) {
		const header = hdata.data;
		const rows = [];

		hdata.nonEmpty.forEach((v, i) => {
			rows.push({
				id: v + 1,
				header: header.map(h => {
					h =
						h[i] && !webix.isUndefined(h[i].text) ? h[i] : { text: h[i] || "" };

					const op = hdata.meta && hdata.meta[i] && hdata.meta[i].operation;
					if (op) h.operation = op;
					return h;
				}),
				format: hdata.meta && hdata.meta[i] && hdata.meta[i].format,
			});
		});

		return rows;
	}
	/**
	 * Sets dimensions based on rows/columns from structure
	 */
	setDimensions() {
		this._reng.resetDimensions();
		const columns = this.getColumns();
		const fields = columns.concat(this.getRows() || []);

		for (let i = 0; i < fields.length; i++) {
			const field = this.getField(fields[i]);
			this._reng.addDimension({
				id: fields[i],
				table: this._table.id,
				label: fields[i],
				rule: {
					by: field.predicate ? `${field.predicate}(${fields[i]})` : fields[i],
				},
			});
		}
	}
	/**
	 * Prepares chart data
	 * @param {Object} res - object with groupped data
	 * @param {Object} result - object with aggregated data and its parameters
	 * @returns {Array} array with chart data
	 */
	getChartData(result, header) {
		const state = this._state;
		const ops = state.structure.values;

		let data = [];
		let values = [];

		if (result.data.length) {
			const chartType = state.chart.type;
			const groupBy = state.structure.groupBy;

			const first = webix.copy(result.data[0]);
			const axis = groupBy ? header.data[0] : first.map(() => "");

			let count = 0;
			while (first.length) {
				let item = first.splice(0, ops.length);
				if (item.length) {
					let text = axis[count].text;
					if (text === 0) text = "0";

					if (chartType == "pie" || chartType == "donut") {
						for (let i = 0; i < item.length; i++)
							item[i] = { value: item[i], color: ops[i].color };

						if (groupBy)
							data.push({
								text: text || axis[count],
								color: this.getValueColor(count / ops.length, true),
								data: item,
							});
						else data = item;
					} else {
						item.push(text || axis[count]);
						data.push(item);
					}
					count += ops.length;
				} else break;
			}
		}

		for (let i = 0; i < ops.length; i++) {
			const op = ops[i];

			let name = op.operation == "complex" ? op.math : op.name;
			name = Array.isArray(name) ? name.join(",") : name;

			values.push({
				text: name || op.operation,
				operation: op.operation,
				color: op.color,
			});
		}

		return { data, values };
	}
	/**
	 * Gets built-in colors for chart values
	 * @param {boolean} pieGroup - is palette for pie group (customizations)
	 * @returns {Array} color palette
	 */
	getPalette() {
		return [
			["#e33fc7", "#a244ea", "#476cee", "#36abee", "#58dccd", "#a7ee70"],
			["#d3ee36", "#eed236", "#ee9336", "#ee4339", "#595959", "#b85981"],
			["#c670b8", "#9984ce", "#b9b9e2", "#b0cdfa", "#a0e4eb", "#7faf1b"],
			["#b4d9a4", "#f2f79a", "#ffaa7d", "#d6806f", "#939393", "#d9b0d1"],
			["#780e3b", "#684da9", "#242464", "#205793", "#5199a4", "#065c27"],
			["#54b15a", "#ecf125", "#c65000", "#990001", "#363636", "#800f3e"],
		];
	}
	/**
	 * Gets a color from the palette
	 * @param {number} i - row number of the required value
	 * @param {boolean} pieGroup - is palette for pie group (customizations)
	 * @returns {string} HEX color
	 */
	getValueColor(i, pieGroup) {
		const palette = this.getPalette(pieGroup);
		let rowIndex = i / palette[0].length;
		rowIndex = rowIndex == palette.length ? 0 : parseInt(rowIndex, 10);
		const columnIndex = i % palette[0].length;
		return palette[rowIndex][columnIndex];
	}

	clearAll() {
		// fields
		this._app.config.fields = null;
		this._state.fields = [];
		// structure
		this._app.setStructure({});
		// reset aggregated data/table config
		delete this._table;
		this._store = this.getPivotData();
		this._data = [];
		this._filtersHash = {};
	}
}
