import { JetView } from "webix-jet";

export default class Popup extends JetView {
	config() {
		return this.app.getService("jet-win").updateConfig({
			view: "window",
			borderless: true,
			fullscreen: true,
			head: false,
			body: { $subview: true },
		});
	}
}
