import { JetView } from "webix-jet";

export default class TableView extends JetView {
	config() {
		this.Local = this.app.getService("local");
		const state = (this.State = this.getParam("state", true));
		const rows = state.structure.rows.length;

		const table = {
			view: "treetable",
			$mainView: true,
			localId: "data",
			css: "webix_data_border webix_header_border",
			select: true,
			leftSplit: state.mode == "table" ? rows : rows ? 1 : 0,
			resizeColumn: true,
			borderless: true,
			columns: [],
			footer: state.datatable.footer,
		};

		webix.extend(table, state.datatable, true);

		return table;
	}

	init() {
		this.LoadData();

		this.on(this.State.$changes, "structure", (structure, old) => {
			if (old) this.LoadData();
		});

		this.on(this.State.$changes, "datatable", (val, o) => {
			if (o) this.refresh();
		});

		this.on(this.State.$changes, "mode", (val, o) => {
			if (o && o != "chart" && val != "chart") this.refresh();
		});
	}
	/**
	 * Loads data to a table
	 * @returns {Promise} promise that resolves when data is parsed
	 */
	LoadData() {
		return this.Local.getData().then(data => {
			this.UpdateTable(data);
		});
	}
	/**
	 * Updates table data
	 * @param {Array} data - array of data
	 */
	UpdateTable(data) {
		const state = this.State;
		const structure = state.structure;
		const transpose = state.datatable.transpose;

		const freeze = this.CheckFreeze();

		let rightSplit = 0;
		const totalCols = state.datatable.totalColumn;
		let totalOps;
		if (totalCols) {
			const vals = structure.values;
			totalOps =
				totalCols == "sumOnly" ? vals.filter(v => v.operation == "sum") : vals;

			if (transpose && totalOps.length) rightSplit = 1;
			else rightSplit = totalOps.length;
		}

		let leftSplit = 0;
		if (freeze) leftSplit = this.GetLeftColCount();

		if (!data.$ready) {
			const total = data.totalColumn;
			const lastId = data.header[data.header.length - 1].id;

			if (total) {
				for (let i = 0; i < rightSplit; i++) {
					let name = totalOps[i].name;
					if (webix.isArray(name)) name = name.join();

					const header = [{ name: "total", colspan: rightSplit }];

					if (!transpose)
						header.push({
							text: name || totalOps[i].operation,
							operation: totalOps[i].operation,
						});

					data.header.push({
						header,
						id: lastId + i + 1,
					});
				}
			}

			const totalIndex = { i: 0 };
			data = this.Local._store = {
				data: data.data.map(item =>
					this._prepareData(item, total, totalIndex, lastId, transpose)
				),
				columns: this.SetColumns(
					data.header,
					data.footer,
					data.marks,
					transpose,
					lastId
				),
				$ready: 1,
			};
		}

		const table = this.$$("data");
		table.clearAll();
		table.define(
			{
				leftSplit,
				rightSplit: freeze ? rightSplit : 0,
			},
			true
		);
		table.refreshColumns(data.columns);
		table.parse(data.data);
	}
	/**
	 * Prepares table item (updates id, adds total cols, etc.)
	 * @param {Array} obj - data item
	 * @param {Array} total - array with total values for columns
	 * @param {Object} index - current total index
	 * @param {number} lastId - last column id
	 * @param {boolean} transpose - values on the row axis
	 * @returns {Proxy} prepared item
	 */
	_prepareData(obj, total, index, lastId, transpose) {
		const _ = this.app.getService("locale")._;
		const state = this.State;
		const structure = state.structure;

		const table = state.mode == "table";
		const values = structure.values;
		const rows = structure.rows.length;

		let totalIndex;

		if (table || !obj.data) totalIndex = index.i++;

		const item = new Proxy(obj, {
			get: (item, prop) => {
				if (prop == "data") return item.vals;
				if (table && prop == "id") return totalIndex + 1;

				const id = prop * 1;

				if (!obj.vals && transpose) {
					if ((table && id == rows + 1) || (!table && id == 1)) {
						const value = values[totalIndex % values.length];

						const operation = value.operation || "complex";
						let fields, math;

						if (operation == "complex") math = value.math;
						else fields = webix.isArray(value.name) ? value.name : [value.name];

						return this.OperationTemplate(operation, fields, math, _);
					}
				}

				if (!isNaN(id)) {
					const vals = typeof item.values == "object" ? item.values : item;

					if (total && id > lastId && (totalIndex || totalIndex === 0)) {
						return total[totalIndex][id - lastId - 1];
					}

					return vals[id - 1];
				}

				return item[prop];
			},
		});

		if (obj.data) {
			item.open = true;
			item.vals = obj.data.map(r => {
				return this._prepareData(r, total, index, lastId, transpose);
			});
		}

		return item;
	}
	GetLeftColCount() {
		const state = this.State;
		const structure = state.structure;
		const rows = structure.rows.length;
		let left = 0;

		if (state.mode == "table") {
			let vals = 0;
			if (state.datatable.transpose) vals += structure.values.length ? 1 : 0;
			left = rows + vals;
		} else left = rows ? 1 : 0;

		return left;
	}
	/**
	 * Checks if columns should be frozen
	 */
	CheckFreeze() {
		const freezeColumns = this.app.config.freezeColumns;
		const compact = this.getParam("compact", true);

		return webix.isUndefined(freezeColumns) ? !compact : freezeColumns;
	}
	/**
	 * Prepares table columns
	 * @param {Array} columns - array with columns
	 * @param {Array} footer - array with total values for columns
	 * @param {Array} marks - array with min/max marks for columns
	 * @param {boolean} transpose - values on the row axis
	 * @param {number} lastId - last column id (exclude total cols)
	 * @returns {Array} column configurations
	 */
	SetColumns(columns, footer, marks, transpose, lastId) {
		const state = this.State;
		columns = webix.copy(columns);
		const _ = this.app.getService("locale")._;

		const rows = state.structure.rows.length;

		let left = this.GetLeftColCount();

		for (let i = 0; i < columns.length; i++) {
			if (i < left) {
				this.SetFirstColumn(
					columns[i],
					!i && this.State.datatable.footer,
					_,
					transpose
				);
			} else {
				columns[i].sort = "int";
				const commonFormat = this.app.config.format;
				const vals = state.structure.values;
				const colId = columns[i].id;

				if (transpose) {
					columns[i].template = (obj, common, value, column, index) => {
						let format;
						let operation;
						if (state.mode == "table") {
							operation = vals[index % vals.length];
							format = operation.format;
						} else {
							if (obj.$level == rows + 1) {
								const startIndex = this.$$("data").getIndexById(obj.$parent);
								operation = vals[index - startIndex - 1];
								format = operation.format;
							}
						}
						format = format || commonFormat || this.CellFormat;
						return format(value, operation);
					};
				} else if (!columns[i].format)
					columns[i].template = (obj, common, value, column) => {
						let format;
						let operation;
						if (column.id > lastId) {
							const totalCols = state.datatable.totalColumn;
							const totalOps =
								totalCols == "sumOnly"
									? vals.filter(v => v.operation == "sum")
									: vals;
							operation = totalOps[column.id - lastId - 1];
							format = operation.format;
						}
						return (format || commonFormat || this.CellFormat)(
							value,
							operation
						);
					};

				if (marks.length)
					columns[i].cssFormat = (v, row, rid, cid) => {
						const col = marks[rid - 1];
						const css = col ? col[cid - 1] : null;
						return css ? css.join(" ") : "";
					};

				const header = columns[i].header;
				for (let j = 0; j < header.length; j++) {
					let h = header[j];
					if (h) {
						if (!j && h.name == "total") {
							h.text = _("Total");
						} else if (j == header.length - 1) {
							h.text = this.HeaderTemplate(h, _, transpose);
						}
					}
				}

				if (footer.length) {
					if (footer[colId - 1])
						columns[i].footer = footer[colId - 1].map((v, index) => {
							let format;
							let operation;
							if (transpose) {
								const footer = state.datatable.footer;
								const ops =
									footer == "sumOnly"
										? vals.filter(v => v.operation == "sum")
										: vals;
								operation = ops[index];
								format = operation.format;
							} else {
								format = columns[i].format;
								operation = vals[(i - left) % vals.length];
							}
							return (format || commonFormat || this.CellFormat)(v, operation);
						});
					else columns[i].footer = "";
				}
			}
		}

		return columns;
	}
	/**
	 * Prepares first column
	 * @param {Object} column - column configuration
	 * @param {boolean} footer - column with footer name
	 * @param {function} _ - function for translating text labels
	 * @param {boolean} transpose - values on the row axis
	 */
	SetFirstColumn(column, footer, _, transpose) {
		if (this.State.mode == "tree") {
			column.header = {
				text: this.State.structure.rows
					.map(field => this.Local.getField(field).value)
					.join("<span class='webix_icon wxi-angle-right'></span>"),
				css: "webix_pivot_tree_header",
			};
			column.width = 300;
			column.template = (obj, common) => {
				return common.treetable(obj, common) + obj[1];
			};
		} else {
			const field = this.Local.getField(column.header[0].text);
			column.header = field ? field.value : "";
			column.width = 200;
		}
		if (footer) {
			if (transpose) {
				const vals = this.State.structure.values;
				const totalOps =
					footer == "sumOnly" ? vals.filter(v => v.operation == "sum") : vals;

				column.footer = totalOps.map(v =>
					this.OperationTemplate(
						v.operation || "complex",
						webix.isArray(v.name) ? v.name : [v.name],
						v.math,
						_
					)
				);
			} else column.footer = _("Total");
		}
	}
	/**
	 * Sets a template for column header
	 * @param {Object} line - object with header line properties
	 * @param {function} _ - function for translating text labels
	 * @param {boolean} transpose - values on the row axis
	 * @returns {string} string with header text
	 */
	HeaderTemplate(line, _, transpose) {
		if (transpose) return line.text;
		else {
			const operation = line.operation || "complex";
			let math, fields;

			if (operation == "complex") math = line.text;
			else fields = line.text.split(",");

			return this.OperationTemplate(operation, fields, math, _);
		}
	}
	/**
	 * Formats a cell value
	 * @param {(number|string)} value - raw value
	 * @returns {(number|string)} formatted value
	 */
	CellFormat(value) {
		if (!value) value = value === 0 ? "0" : "";

		return value ? parseFloat(value).toFixed(3) : value;
	}
	/**
	 * Sets a template for value
	 * @param {string} operation - operation name
	 * @param {array} fields - array of field names
	 * @param {string} math - complex operation math
	 * @param {function} _ - function for translating text labels
	 * @returns {string} string with value text
	 */
	OperationTemplate(operation, fields, math, _) {
		if (operation == "complex") return this.Local.fixMath(math);
		else {
			const text = fields.map(t => this.Local.getField(t).value).join(", ");
			return `${text} <span class="webix_pivot_operation">${_(
				operation
			)}</span>`;
		}
	}
}
