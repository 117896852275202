import { JetView } from "webix-jet";
import ModeView from "views/mode";
import filterPopup from "views/filter";

export default class ToolbarView extends JetView {
	config() {
		const _ = this.app.getService("locale")._;
		this.Compact = this.getParam("compact");
		this.State = this.getParam("state");

		let config;
		if (this.Compact) {
			config = {
				view: "icon",
				icon: "pt-settings",
				inputHeight: webix.skin.$active.buttonHeight,
				on: {
					onItemClick: () => this.ToggleConfig(),
				},
			};
		} else {
			const label = _("Configure Pivot");
			const css = "webix_template webix_pivot_measure_size";

			// 20px icon width and 8px additional padding
			const width = 20 + 8 + webix.html.getTextSize(label, css).width;
			config = {
				view: "template",
				borderless: true,
				width,
				template: () => {
					return `
						<span>${label}</span>
						<span class="pt-settings webix_pivot_toolbar_icon"></span>`;
				},
				onClick: {
					webix_pivot_settings: () => this.ToggleConfig(),
				},
			};
		}
		config.localId = "config";
		config.css = "webix_pivot_settings";
		config.tooltip = _("Click to configure");

		const scrollSize = webix.env.$customScroll ? 0 : this._GetScrollWidth();
		const height = webix.skin.$active.toolbarHeight + scrollSize;

		let css = "webix_pivot_toolbar";
		if (scrollSize) {
			webix.html.addStyle(
				`.webix_pivot_bar_with_scroll .webix_pivot_settings>.webix_template{ line-height: ${height}px; }`
			);
			css += " webix_pivot_bar_with_scroll";
		}

		const result = {
			css,
			margin: this.Compact ? 12 : 0,
			padding: {
				left: this.Compact ? webix.skin.$active.inputPadding : 0,
			},
			height,
			cols: [config, this.GetFilters()],
		};

		if (!this.Compact) result.cols.push(ModeView);

		return result;
	}

	init() {
		this.filterPopup = this.ui(filterPopup);

		this.on(this.State.$changes, "fields", fields => {
			if (fields.length) {
				// render filters after fields set
				const filtersView = this.$$("filters").getParentView();
				webix.ui(this.GetFilters(), filtersView);
			}
		});

		this.on(this.State.$changes, "structure", (structure, old) => {
			if (old && this.FiltersChanged(structure, old)) {
				const filtersView = this.$$("filters").getParentView();
				webix.ui(this.GetFilters(), filtersView);
			}
		});

		this.on(this.State.$changes, "readonly", val => {
			this.ToggleReadonly(val);
		});
	}
	/**
	 * Compares filters when structure changes
	 * @param {Object} structure - current structure
	 * @param {Object} oldStructure - previous structure
	 * @returns {boolean} result of comparison
	 */
	FiltersChanged(structure, oldStructure) {
		if (structure.filters.length == oldStructure.filters.length) {
			for (let i = 0; i < structure.filters.length; i++) {
				const filter = structure.filters[i];
				const old = oldStructure.filters[i];
				if (filter.name != old.name || filter.external != old.external)
					return true;
				if (JSON.stringify(filter.value) != JSON.stringify(old.value))
					return true;
			}
		} else return true;

		return false;
	}
	/**
	 * Toggles the configuration window
	 */
	ToggleConfig() {
		this.State.config = !this.State.config;
	}
	/**
	 * Controls visibility of a button with Pivot settings
	 * @param {boolean} val - indicates whether Pivot is in a readonly mode
	 */
	ToggleReadonly(val) {
		if (val) this.$$("config").hide();
		else this.$$("config").show();
	}
	/**
	 * Gets configuration for toolbar filters
	 * @returns {Object} layout with all filters
	 */
	GetFilters() {
		const structure = this.State.structure;
		const filters = [];

		if (this.State.fields.length)
			// toolbar renders before fields set
			structure.filters.forEach(filter => {
				if (!filter.external) filters.push(this.FilterConfig(filter));
			});

		const askin = webix.skin.$active;
		const padding = (askin.toolbarHeight - askin.buttonHeight) / 2;
		return {
			view: "scrollview",
			borderless: true,
			scroll: "x",
			body: {
				margin: 8,
				padding: {
					left: this.Compact ? 0 : 8,
					top: padding + askin.inputPadding,
					bottom: padding + askin.inputPadding,
				},
				localId: "filters",
				cols: filters,
			},
		};
	}
	/**
	 * Gets configuration a single toolbar filter
	 * @param {Object} filter - filter object from structure
	 * @returns {Object} filter UI
	 */
	FilterConfig(filter) {
		const label = this.app.getService("local").getField(filter.name).value;
		const css = "webix_template webix_pivot_measure_size";

		// 20px icon width and 8px additional padding
		const width =
			(this.Compact ? 0 : 20 + 8) + webix.html.getTextSize(label, css).width;
		return {
			view: "template",
			borderless: true,
			width,
			css: "webix_pivot_filter",
			template: () => {
				const value = filter.value;
				const activeCss =
					value && (value.includes || value.condition.filter)
						? "webix_pivot_active_filter"
						: "";
				const icon = !this.Compact
					? "<span class='pt-filter webix_pivot_toolbar_icon'></span>"
					: "";
				return `<div class="webix_pivot_filter_inner ${activeCss}">
					<span>${label}</span>
					${icon}
				</div>`;
			},
			onClick: {
				webix_pivot_filter: function() {
					this.$scope.filterPopup.Show(this.$view, filter);
				},
			},
		};
	}

	_GetScrollWidth() {
		const outer = webix.html.create("DIV", {
			style: "visibility:hidden;overflow:scroll;",
		});
		const inner = webix.html.create("DIV");

		document.body.appendChild(outer);
		outer.appendChild(inner);

		const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

		outer.parentNode.removeChild(outer);
		return scrollbarWidth;
	}
}
